import Img from 'gatsby-image';
import * as _ from 'lodash';
import * as React from 'react';
import * as main from '../layouts/MainLayout.css';
import * as css from './Article.css';
import { PageContext } from '../templates/post';
import ArticleContent from './ArticleContent';

export interface FullPostProps {
  className: string;
  post: PageContext;
}

export const Article: React.FunctionComponent<FullPostProps> = ({ className, post }) => {
  return (
    <article className={className}>
      {post.frontmatter.image && (
        <Img className={css.image} alt={`${post.frontmatter.title} cover image`}
          fluid={post.frontmatter.image.childImageSharp.fluid}/>
      )}
      <div className={`${css.headline} ${main.heading}`}>
        {/* <Link className={css.avatar} to={`/author/${_.kebabCase(post.frontmatter.author.id)}/`}> */}
        <div className={css.avatar}>
          <img title={post.frontmatter.author.id} style={{display: "inline-block"}} alt={`${post.frontmatter.title} cover image`}
            src={post.frontmatter.author.avatar.children[0].fixed.src}/>
        </div>
        {/* </Link> */}
        {/* <DateBadge className={css.date} year={post.frontmatter.date_year} month={post.frontmatter.date_month} day={post.frontmatter.date_day}/> */}
        <div className={css.date}>
        {/* <Link to={`/author/${_.kebabCase(post.frontmatter.author.id)}/`}> */}
          {post.frontmatter.author.id}
        {/*</Link>*/} - {post.frontmatter.date_month} {post.frontmatter.date_day}, {post.frontmatter.date_year}
        </div>
        <h1 className={css.title}>{post.frontmatter.title}</h1>
      </div>
      <ArticleContent htmlAst={post.htmlAst} />
      {/* <div className={css.tags}>
        {post.frontmatter.tags && post.frontmatter.tags.map(t => {
          return <div className={css.tag}>#{t}</div>;
          })
        }
      </div> */}
    </article>
  );
};

export default Article;
