import { css } from '@emotion/css';

export const image = css`
  height: 8em;
  box-shadow: .05em .05em .1em 0px rgba(0, 0, 0, .5);
`;

export const headline = css`
  position: relative;
  display: grid;
  grid-template-columns: 0fr 1fr 1fr;
  grid-template-rows: 0fr 1fr;
  margin: 1.5em 0;
  z-index: 1;

  .post-card-image-link {
    overflow: hidden;
  }
`;

export const avatar = css`
  grid-row: 1 / span 2;
  grid-column: 1;
  margin-left: -0.5em;
  margin-right: 0.75em;
  margin-top: -5.5em;
  margin-bottom: 0.25em;
  height: 3.25em;
  width: 3.25em;

  img {
    height: 100%;
    width: 100%;
    box-shadow: 0.1em 0.1em 1em 0 black;
    border-radius: 100%;
    background-color: white;
    /* box-shadow: 0.01em 0.01em 0.1em 0px rgba(0,0,0,.75); */
  }
`;

export const date = css`
  grid-row: 1;
  grid-column: 1 / span 3;
  font-size: 75%;
  font-style: italic;
  color: rgba(255, 255, 255, 0.5);
  white-space: nowrap;
  margin-bottom: 0.25em;
  /* transform: rotate(5deg); */
`;

export const tags = css`
  /* transform: rotate(5deg); */
  color: rgba(255, 255, 255, 0.3);
  white-space: nowrap;
  /* overflow: hidden;
  text-overflow: ellipsis; */
`;

export const nzPostTags = css`
  font-size: smaller;
  color: rgba(255, 255, 255, 0.5);
  margin-right: 0;

  div {
    display: inline-block;
    margin-left: 1em;
  }
`;

export const tag = css`
  display: inline-block;
  margin-left: 1em;
`;

export const title = css`
  grid-row: 2;
  grid-column: 1 / span 3;
  margin-bottom: 0.25em;
  font-size: 150%;
  margin: 0;
  color: white;
  line-height: 1;
  /* grid-row: 1 / span 2;
  grid-column: 2; */
  /* white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; */
`;
