import { lighten, darken, setSaturation } from 'polished';
import styled from '@emotion/styled';

import { colors } from '../styles/colors';

export const PostFullContent = styled.section`
  position: relative;
  margin: 0;
  line-height: 1.25em;

  p {
    word-wrap: break-word;
    overflow-wrap: break-word;
    -webkit-hyphens: auto;
    -moz-hyphens: auto;
    hyphens: auto;
  }

  .gatsby-resp-image-wrapper span {
    width: 100% !important;
    max-width: 100% !important;
  }

  .gatsby-resp-image-wrapper img {
    width: 100% !important;
    max-width: 100% !important;
    box-shadow: .01em .01em .1em 0px rgba(0, 0, 0, .5) !important;
  }

  @media (max-width: 1170px) {
  }

  @media (max-width: 800px) {
  }

  :before {
    content: '';
    position: absolute;
    top: 15px;
    left: -5px;
    z-index: -1;
    display: block;
    width: 20px;
    height: 200px;
    background: rgba(39, 44, 49, 0.15);
    filter: blur(5px);
    transform: rotate(-5deg);
  }

  :after {
    content: '';
    position: absolute;
    top: 15px;
    right: -5px;
    z-index: -1;
    display: block;
    width: 20px;
    height: 200px;
    background: rgba(39, 44, 49, 0.15);
    filter: blur(5px);
    transform: rotate(5deg);
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  ul,
  ol,
  dl,
  pre,
  blockquote,
  .post-full-comments,
  .footnotes {
    max-width: 100% !important;
  }

  li {
    word-break: break-word;
  }

  li p {
    margin: 0;
  }

  a {
    word-break: break-word;
    text-decoration: underline;
    color: #8DFFA6;
    /* color: #FFFFA0; */
  }

  a:hover {
    /* text-decoration: none; */
  }

  strong {
    /* color: #87F7A5; */
    color: #87F7A5;
    font-weight: normal;
    /* text-shadow: 0 0 0.2em black; */
  }

  em {
    font-style: italic;
  }

  small {
    display: inline-block;
    line-height: 1.6em;
  }

  li:first-child {
    margin-top: 0;
  }

  img,
  video {
    display: block;
    margin: 1.5em auto;
    max-width: 100%;
    height: auto;
  }
  @media (max-width: 1040px) {
    img,
    video {
      width: 100%;
    }
  }

  img[src$='#full'] {
    max-width: none;
    width: 100vw;
  }

  img + br + small {
    display: block;
    margin-top: -3em;
    margin-bottom: 1.5em;
    text-align: center;
  }

  /* Override third party iframe styles */
  iframe {
    margin: 0 auto !important;
  }

  blockquote {
    margin: 0 0 1.5em;
    margin-left: 0.75em;
    /* padding: 0 0.75em; */
    /* border-left: #3eb0ef 1px dotted; */
  }

  blockquote p {
    margin: 0 0 1em 0;
    color: rgba(240, 240, 240, 0.8);
    font-size: 100%;
    line-height: inherit;
    font-style: italic;
  }

  blockquote p:last-child {
    margin-bottom: 0;
  }

  code {
    padding: 0 5px 2px;
    font-size: 0.9em;
    line-height: 1em;
    font-weight: 400 !important;
    background: rgba(0, 0, 0, 0.1);
    box-shadow: .01em .01em .1em 0px rgba(0, 0, 0, 1);
    border-radius: 3px;
    color: #FFFFD8;
  }

  p code {
    word-break: break-all;
    white-space: nowrap;
  }

  pre {
    overflow-x: auto;
    font-size: inherit;
    background-color: rgba(0, 0, 0, 0.1);
    box-shadow: .01em .01em .2em 0px rgba(0, 0, 0, .5);
    padding: 0.5em;
    max-width: 100%;
  }

  pre code {
    padding: 0;
    font-size: 82%;
    line-height: 1.5;
    background: transparent;
    border: none;
    box-shadow: none;
    color: unset;
    font-style: unset;
    text-decoration: unset;
  }
  @media (max-width: 500px) {
    pre code {
      font-size: 52%;
    }
  }
  @media (max-width: 350px) {
    pre code {
      font-size: 45%;
    }
  }

  pre code :not(span) {
    color: inherit;
  }

  /* .fluid-width-video-wrapper { */
  .gatsby-resp-iframe-wrapper {
    margin: 1.5em 0 3em;
  }

  hr {
    margin: 4vw 0;
  }

  hr:after {
    content: '';
    position: absolute;
    top: -15px;
    left: 50%;
    display: block;
    margin-left: -10px;
    width: 1px;
    height: 30px;
    /* background: color(var(--lightgrey) l(+10%)); */
    background: ${lighten('0.1', colors.lightgrey)};
    box-shadow: #fff 0 0 0 5px;
    transform: rotate(45deg);
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: white;
    font-family: Calibri, Arial, Tahoma;
  }

  h1 {
    margin: 0.5em 0 0.2em 0;
    font-size: 125%;
    font-weight: bold;
    /* border-left: .2ch solid #00B3FF;
    padding: 0 1em; */
  }
  @media (max-width: 500px) {
    h1 {
      font-size: 125%;
    }
  }

  h2 {
    margin: 0.5em 0 0.2em 0;
    font-size: 115%;
    font-weight: bold;
    /* border-left: .217ch solid #00B3FF;
    padding: 0 1em; */
  }
  @media (max-width: 500px) {
    h2 {
      font-size: 125%;
    }
  }

  h3 {
    margin: 0.5em 0 0.2em 0;
    font-size: 105%;
    font-weight: bold;
    /* border-left: .238ch solid #00B3FF;
    padding: 0 1em; */
  }
  @media (max-width: 500px) {
    h3 {
      font-size: 105%;
    }
  }

  h4 {
    margin: 0.5em 0 0.2em 0;
    font-size: 2.8rem;
    font-weight: 700;
  }
  @media (max-width: 500px) {
    h4 {
      font-size: 2.2rem;
    }
  }

  h5 {
    display: block;
    margin: 0.5em 0;
    padding: 1em 0 1.5em;
    border: 0;
    color: ${colors.blue};
    font-family: Calibri, Arial, Tahoma;
    font-size: 3.2rem;
    line-height: 1.35em;
    text-align: center;
  }
  @media (min-width: 1180px) {
    h5 {
      max-width: 1060px;
    }
  }
  @media (max-width: 500px) {
    h5 {
      padding: 0 0 0.5em;
      font-size: 2.2rem;
    }
  }

  h6 {
    margin: 0.5em 0 0.2em 0;
    font-size: 2.3rem;
    font-weight: 700;
  }
  @media (max-width: 500px) {
    h6 {
      font-size: 2rem;
    }
  }

  /* Tables */
  table {
    display: inline-block;
    overflow-x: auto;
    margin: 0.5em 0 2.5em;
    max-width: 100%;
    width: auto;
    border-spacing: 0;
    border-collapse: collapse;
    font-family: Calibri, Arial, Tahoma;
    font-size: 1.6rem;
    white-space: nowrap;
    vertical-align: top;
  }

  table {
    -webkit-overflow-scrolling: touch;
    background: radial-gradient(ellipse at left, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0) 75%) 0
        center,
      radial-gradient(ellipse at right, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0) 75%) 100% center;
    background-attachment: scroll, scroll;
    background-size: 10px 100%, 10px 100%;
    background-repeat: no-repeat;
  }

  table td:first-child {
    background-image: linear-gradient(
      to right,
      rgba(255, 255, 255, 1) 50%,
      rgba(255, 255, 255, 0) 100%
    );
    background-size: 20px 100%;
    background-repeat: no-repeat;
  }

  table td:last-child {
    background-image: linear-gradient(
      to left,
      rgba(255, 255, 255, 1) 50%,
      rgba(255, 255, 255, 0) 100%
    );
    background-position: 100% 0;
    background-size: 20px 100%;
    background-repeat: no-repeat;
  }

  table th {
    color: ${colors.darkgrey};
    font-size: 1.2rem;
    font-weight: 700;
    letter-spacing: 0.2px;
    text-align: left;
    text-transform: uppercase;
    /* background-color: color(var(--whitegrey) l(+4%)); */
    background-color: ${lighten('0.04', colors.whitegrey)};
  }

  table th,
  table td {
    padding: 6px 12px;
    /* border: color(var(--whitegrey) l(-1%) s(-5%)) 1px solid; */
    border: ${setSaturation('0.05', darken('0.01', colors.whitegrey))} 1px solid;
  }

  @media (max-width: 500px) {
    padding: 0;
    :before {
      display: none;
    }
    :after {
      display: none;
    }
  }

  .language-typescript,
  .language-javascript,
  .language-jsx,
  .language-tsx {
    color: #f0f0f0;

    .token.keyword,
    .token.boolean {
      color: #86ccf6;
    }

    .token.comment {
      color: #808090;
      font-style: italic;
    }

    .token.string,
    .token.number {
      color: #d0d0d0;
    }

    .token.builtin,
    .token.class-name {
      color: #83F1C9;
    }

    .token.function {
      color: #ffffa0;
    }

    .token.tag > .tag {
      color: #86ccf6;
    }

    .token.tag > .punctuation {
      color: #86ccf6;
    }

    .token.tag > .attr-name {
      color: #ffffa0;
    }

    .token.plain-text {
      color: #ffffff;
    }
  }
`;
