import * as React from 'react';
import rehypeReact from 'rehype-react';
import * as css from './ArticleContent.css';

require('../styles/nevod-syntax.css');
require('../styles/artel-syntax.css');

const renderAst = new rehypeReact({
  createElement: React.createElement,
  // components: { 'interactive-counter': Counter },
  components: {},
}).Compiler;

const Ast = ({ ast, ...props }: any) => {
  ast.properties = props;
  return renderAst(ast);
};

export interface ArticleContentProps {
  htmlAst: any;
}

const ArticleContent: React.FunctionComponent<ArticleContentProps> = ({ htmlAst }) => {
  return (
    <css.PostFullContent className="post-full-content">
      {/* TODO: this will apply the class when rehype-react is published https://github.com/rhysd/rehype-react/pull/11 */}
      <Ast className="post-content" ast={htmlAst} />
    </css.PostFullContent>
  );
};

export default ArticleContent;
